<template>
  <div>
    <div class="container viewContainer" style="text-align: center;">
      <h1>Persönliche Beratung bei Ihrem lokalen Mieterverein</h1>
      <p>
        Brauchen Sie eine persönliche Beratung? Einer unserer 300 Mietervereine in mehr als 500 Standorten ist auch in Ihrer Nähe. Geben Sie Ihre Postleitzahl oder einen Ortsnamen ein und erhalten Sie die vollständigen Kontaktdaten Ihres örtlichen Mietervereins.
      </p>
      <p class="plzSearchContainer">
        <input v-model="plz" placeholder="PLZ eingeben" type="number" class="form-control rentInput">
        <button @click="loadByPlz()">
          <img :src="require('@/assets/img/search_white_24dp.svg')">
        </button>
      </p>
      <div v-if="mietervereinArray.length > 0">
        <p v-for="mieterverein in mietervereinArray" :key="mieterverein" class="plzResultContainer">
          <strong>{{ mieterverein.name_1 }}</strong><br><br>
          {{ mieterverein.strasse }} {{ mieterverein.ort }}<br><br>
          <span v-if="mieterverein.tel_1">Telefon: <a :href="'tel:' + mieterverein.tel_1">{{ mieterverein.tel_1 }}</a><br></span>
          <span v-if="mieterverein.email">E-Mail: <a :href="'mailto:' + mieterverein.email">{{ mieterverein.email }}</a><br></span>
          <a v-if="mieterverein.homepage" target="blank" :href="'https://' + mieterverein.homepage">zur Webseite</a>
        </p>
        <p v-if="noResultsHint" style="padding-top: 8px" class="alert">
          Zu Ihrer Eingabe wurde kein Ergebnis gefunden.
        </p>
      </div> 
    </div>
  </div>  
</template>
<script>
  import DataService from '@/services/DataService'
  export default {
    name: 'Hilfe',
    components: {
    },
    data() {
      return {
        plz: '',
        mietervereinArray: [],
        noResultsHint: false,
      }
    },
    methods: {
      loadByPlz() {
        DataService.getPlzInfos(this.plz).then((response) => {
          console.log(response)
          var results = response.data.results
          if (results.length > 0) {
            this.mietervereinArray = results
            noResultsHint = false
          } else {
            this.mietervereinArray = []
            this.noResultsHint = true
          }
        })
        .catch(err => {
         console.log(err) 
        });
      }
    }
  }
</script>