<template>
  <div>
    <div class="home container">
      <div class="euroBadge onlyDesktop" />
      <img class="logo onlyMobile" :src="require('@/assets/img/Smartmieteter_Logo_final.png')">
      <h1 style="position: relative; margin-top: 16px;" class="onlyMobile">
        Miete mindern bei Mängeln?
        <span style="top: 0px;" class="infoButton" type="button" data-bs-target="#hinweisModal" data-bs-toggle="modal">
          i
        </span>
      </h1>
      <div class="spacer s" />
      <p class="onlyMobile" style="font-size: 16px;">Errechnen Sie in 2 Minuten, was drin ist.</p>
      <p class="onlyDesktop">
        Wohnungsmängel muss die Vermieterin bzw. der Vermieter beseitigen. Das Gesetz gibt den Mietenden das Recht bis zur Beseitigung des Mangels zu mindern, wenn die Wohnung bis dahin nur eingeschränkt nutzbar ist. 
      </p>
      <p><strong>Sie wollen wissen, ob und wie viel Sie mindern können?</strong></p>
      <p class="onlyDesktop">
        Hier erhalten Sie kompetente Informationen und Unterstützung vom Deutschen Mieterbund mit seinen rund 300 örtlichen Mietervereinen.
      </p>
      <div class="spacer m" />
      <router-link to="/mangel"><button type="button" class="btn btn-primary"><span>Berechnung starten</span></button></router-link>
      <div class="spacer s" />
      <a class="iconLink share" @click="inputShown = true">Rechner teilen</a>
      <div v-if="inputShown" class="urlInputContainer">
        <input v-model="url" class="urlInput" read-only>
        <button @click="copyToClipboard">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" /></svg>
        </button>
      </div>
      <p v-if="message!==''" class="copyMessage">{{ message }}</p>
      <div class="spacer l" />
    </div>
    <div id="hinweisModal" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <p>
              Wohnungsmängel muss die Vermieterin bzw. der Vermieter beseitigen. Das Gesetz gibt den Mietenden das Recht bis zur Beseitigung des Mangels zu mindern, wenn die Wohnung bis dahin nur eingeschränkt nutzbar ist. 
            </p>
            <p>
              Hier erhalten Sie kompetente Informationen und Unterstützung vom Deutschen Mieterbund mit seinen rund 300 örtlichen Mietervereinen.
            </p>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'Home',
    components: {
    },
    data() {
      return {
          test: '',
          inputShown: false,
          url: window.location.protocol + '//'+ window.location.hostname,
          message: ''
      }
    },
    created() {
      this.$store.commit('setSelectedCase', {})
      this.$store.commit('setSelectedGrade', {})
      this.$store.commit('setSelectedTime', {})
      this.$store.commit('setSelectedRent', '')
      this.$store.commit('setCurrentProgress', 0)
      this.message = ""
    },
    methods: {
      copyToClipboard() {
        let text = this.url;
        navigator.clipboard
          .writeText(text)
          .then(
            success => this.message = 'Link in Zwischenablage kopiert', 
            err => this.message = 'Speichern in Zwischenablage leider nicht möglich', 
          );
      }
    }
  }
</script>
 