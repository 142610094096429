<template>
  <div class="outerContainer">
    <div :class="'desktopContainer ' + $route.name">
      <div class="euroBadge" />
      <div class="logoContainer">
        <img class="logo logoStart" :src="require('@/assets/img/Smartmieteter_Logo_final.png')">
        <h4 class="modal-title">Miete mindern bei Mängeln?</h4>
        <p style="margin-top: 40px; font-size: 16px;">Errechnen Sie in 2 Minuten, was drin ist.</p>
        <div class="eulenContainer">
          <img class="eule" :src="require('@/assets/img/eule.png')">
          <div class="eye" :style="leftEyeStyle"></div>
          <div class="eye" :style="rightEyeStyle"></div>
        </div>
      </div>
    </div>
    <div class="mainContainer">
      <Header />
      <router-view class="routerContainer" />
    </div>
    <router-link class="imressumLink" to="/impressum">Impressum</router-link>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import Header from '@/components/Header'
  export default {
    name: 'App',
    components: {
      Header
    },
    data() {
      return { 
        mouseX: 0,
        mouseY: 0
      }
    },
    computed: {
      leftEyeStyle() {
          return {
              transform: `translate(${this.leftPupilX}px, ${this.leftPupilY}px)`
          };
      },
      rightEyeStyle() {
          return {
              transform: `translate(${this.rightPupilX}px, ${this.rightPupilY}px)`
          };
      },
      leftPupilX() {
          var translation = 8 + (this.mouseX - 25) * 0.01
          if ( translation > 26) {
            translation = 26
          }
          if ( translation < 12) {
            translation = 12
          }
          return translation
      },
      leftPupilY() {
          return 8 + (this.mouseY - 25) * 0.01;
      },
      rightPupilX() {
          var translation = 40 + (this.mouseX - 75) * 0.01;
          if ( translation > 58) {
            translation = 58
          }
          if ( translation < 44) {
            translation = 44
          }
          return translation
      },
      rightPupilY() {
          return 8 + (this.mouseY - 25) * 0.01;
      },
      ...mapGetters([
        'getSession'
      ])
    },
    methods: {
      onMouseMove(event) {
          this.mouseX = event.clientX;
          this.mouseY = event.clientY;
      }
  },
  mounted() {
      document.addEventListener('mousemove', this.onMouseMove);
  },
  beforeUnmount() {
      document.removeEventListener('mousemove', this.onMouseMove);
  },
  created () {
    if (this.getSession.sessionId == null) {
      this.$store.commit('createSession')
    }
  }
  }
</script>