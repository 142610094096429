<template>
  <div>
    <div class="container viewContainer" style="text-align: center;">
      <h1>Ihre Berechnung per Mail</h1>
      <p>
        Jeder Fall ist individuell, wir empfehlen Ihnen daher sich fachkundig beraten zu lassen. Bringen Sie die Berechnung als Grundlage zu ihrem Beratungsgespräch mit.  
      </p>
      <p>
        Lassen Sie sich hier die Berechnung ihrer Mietminderung per E-Mail zusenden.
      </p>
      <input v-model="email" type="email" class="form-control" placeholder="E-Mail-Adresse eingeben">
      <div class="spacer m" />
      <button type="button" class="btn btn-primary" @click="sendMail"><span>Berechnung anfordern</span></button>
      <p style="padding: 20px 0">{{ errorText }}</p>
    </div>
  </div>  
</template>
<script>
  import { mapGetters } from 'vuex'
  import DataService from '@/services/DataService'
  export default {
    name: 'Hilfe',
    components: {
    },
    data() {
      return {
        email: '',
        errorText: '',
        result: '',
        days: '',
        daysTotal: 0,
        min: 0,
        max: 0
      }
    },
    computed: {
      ...mapGetters([
          'getSelectedCase', 'getSelectedGrade', 'getSelectedTime', 'getSelectedRent'
      ])
    },
    created () {
      this.calcResult()
    },
    methods: {
      sendMail() {      
        var text = "<p>Vielen Dank für die Benutzung unseres Hilfe-Tools Smart Mieter. Anbei finden Sie die Berechnung Ihrer Mietminderung.</p>"
        text += "<p>Jeder Fall einer Mietminderung ist individuell und sollte von einer fachkundigen Person geprüft werden. Die Berechnung Ihrer Mietminderung dient als Grundlage für die weitere Beratung. Die Expertinnen und Experten des Mieterbunds stehen Ihnen mit ihrer Expertise zur Verfügung:</p>"
        text += "<p><a href='https://smart-mieter.mieterbund.de/direkthilfe'>Direkthilfe per Telefon oder E-Mail </a></p>"
        text += "<p><a href='https://smart-mieter.mieterbund.de/mieterverein'>Hilfe bei ihrem lokalen Mieterverein</a></p>"
        //Berechnung
        text += "<p>"
        text += "<strong>Ihre Angaben</strong><br>"
        text += "Mangel: " + this.getSelectedCase.name + "<br>"
        text += "Schweregrad: " + this.getSelectedGrade.headline + "<br>"
        if (this.getSelectedTime.informed ) {
          // text += "Minderungszeitraum:" +  transformToFormat(this.getSelectedTime.informed) + " bis " + transformToFormat(this.getSelectedTime.to) + " = " + this.days + " Tage <br>"
        }
        text += "Monatsmiete: " + this.getSelectedRent + "€"
        text += "</p>"
        text += "<p>"
        text += "<strong>Minderungsquote:</strong><br>"
        if(this.min!==this.max) {
          text += this.min + "-" + this.max
        } else {
          this.min
        }
        text += "</p>"
        if(this.isInPast() == false) {
          text += "<p>"
          text += "<strong>Monatlicher Anspruch:</strong><br>"
          if(this.min!==this.max) {
            text += this.getSelectedRent + " x " + this.min + "-" + this.max + "% (Minderungsquote)"
          } else {
            "x " + this.min + "% (Minderungsquote)"
          }
          text += " = " + (this.getSelectedRent / 100 * this.min).toFixed(0) + "-" + (this.getSelectedRent / 100 * this.max ).toFixed(0) + "€"
          text += "</p>"
        }
        if(this.getSelectedTime.informed) {
        text += "<p>"
        text += "<strong>Anspruch im Minderungszeitraum:</strong><br>"
        text += this.getSelectedRent + "€ / 31 Tage = " + (this.getSelectedRent / 31).toFixed(0) + "€ (Miete pro Tag)"
        text += " x " + this.days + " Tage (Minderungszeitraum)"
        text += " x " + this.min + " - " + this.max +" % (Minderungsquote)"
        text += " = " + (this.getSelectedRent / 31 / 100 * this.min * this.days).toFixed(0) + " - " + (this.getSelectedRent / 31 / 100 * this.max * this.days).toFixed(0) + " €"
        text += "</p>"
        }
        console.log(text)
        DataService.sendMail(this.email, "Smart Mieter - Mietminderung: Ihre Berechnung", text).then((response) => {
            if (response.data != null && response.data.ok == true)
            {
              this.errorText = "Die E-Mail wurde erfolgreich versendet."
            } else {
              this.errorText = "Hier ist was schief gelaufen"
            }
          })
          .catch(err => {
            this.errorText = "Hier ist was richtig schief gelaufen"
          })
      },
      calcResult() {
        var shownResult = 0
        var rent = this.getSelectedRent
        this.min = this.getSelectedGrade.minReduce
        this.max = this.getSelectedGrade.maxReduce
        console.log("MinMax")
        console.log(this.min)
        console.log(this.max)
        var Difference_In_Time = this.getRelevantDays()
        this.days = Difference_In_Time / (1000 * 3600 * 24)
        var minPerDay = rent / 31 * this.min / 100
        var maxPerDay = rent / 31 * this.max / 100
        shownResult = (minPerDay * this.days).toFixed(0) + ' - ' + (maxPerDay * this.days).toFixed(0) + '€'
        if( this.isInPast() === false || !this.getSelectedTime.informed) {
          shownResult = (rent / 100 * this.min).toFixed(0) + " - " + (rent / 100 * this.max).toFixed(0) + " €"
        }
        if (minPerDay == maxPerDay) {
          shownResult = shownResult.split('-')[1] //teile bei -, nimm 2. Element
        }
        this.result = shownResult
      },
      getRelevantDays() {
        var from = new Date(this.getSelectedTime.from)
        var to = new Date(this.getSelectedTime.to)
        var informed = new Date(this.getSelectedTime.informed)
        return to - informed < 0 ? 0 : to - informed
      },
      isInPast() {
        var now = new Date();
        var to = new Date(this.getSelectedTime.to)
        console.log("Hier jetzt schauen:")
        console.log(now)
        console.log(to)
        now.setHours(0,0,0,0)
        console.log(now)
        if (to < now) {
          return true
        } else {
          return false
        }
      },
      transformToFormat(dateString) {
        console.log(dateString)
        let dateObj = new Date(dateString);
        var dd = String(dateObj.getDate()).padStart(2, '0');
        var mm = String(dateObj.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = dateObj.getFullYear();
        var dateString = dd + '.' + mm + "." + yyyy
        return dateString
      }
    }
  }
</script>