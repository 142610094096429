<template>
  <div>
    <div class="container viewContainer">
      <h1 v-if="isInPast() == true && getSelectedTime.informed" style="text-align: center">Das sollten Sie zurück bekommen:</h1>
      <h1 v-if="isInPast() == false || !getSelectedTime.informed" style="text-align: center">Ihre monatliche Minderung beträgt:</h1>
      <div class="spacer m" />
      <span class="resultContainer">{{ result }}</span>
      <div class="spacer m" />
      <div>
        <button data-bs-target="#berechungModal" data-bs-toggle="modal" class="btn btn-secondary">Berechnung einsehen</button>
      </div>
      <div class="spacer m" />
      <h1 style="text-align: center">Wie geht es jetzt weiter?</h1>
      <p style="text-align: center">
        Wir haben Ihre Angaben mit Gerichtsentscheidungen zu ähnlich gelagerten Fällen verglichen und daraus die Bandbreite einer möglichen Minderung ermittelt. Ob und in welcher Höhe Sie konkret einen Anspruch auf Mietminderung haben, hängt von weiteren Faktoren ab und bedarf einer individuellen Prüfung.
      </p>
      <div class="goToNextContainer">
        <button type="button" class="btn btn-primary" @click="$store.commit('setCurrentProgress', 99)"><router-link to="/kontakt"><span>Weiter</span></router-link></button>
      </div>
    </div>
    <!-- Modul Berechnung -->
    <div id="berechungModal" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Berechnung</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body berechnungContainer">
            <p class="gradText">
              <span class="mediumText">Ihre Angaben</span>
              Mangel: {{ getSelectedCase.name }}<br>
              Schweregrad: {{ getSelectedGrade.headline }}<br>
              <span v-if="getSelectedTime.informed">Minderungszeitraum: {{ transformToFormat(getSelectedTime.informed) }} bis {{ transformToFormat(getSelectedTime.to) }} = {{ days }} Tage</span><br>
              Monatsmiete: {{ getSelectedRent }} €
            </p>
            <p class="gradText">
              <span class="mediumText">Minderungsquote:</span>
              <span v-if="min!==max">{{ min }} - {{ max }}%</span>
              <span v-else>{{ min }}%</span>
            </p>
            <p v-if="isInPast() == false" class="gradText">
              <span class="mediumText">Monatlicher Anspruch</span>
              {{ getSelectedRent }} € (Monatsmiete)<br>
              <span v-if="min!==max">x {{ min }} - {{ max }} % (Minderungsquote)</span>
              <span v-else>x {{ min }} % (Minderungsquote)</span>
              <br>
              = {{ (getSelectedRent / 100 * min).toFixed(0) }} - {{ (getSelectedRent / 100 * max).toFixed(0) }} €
            </p>
            <p v-if="getSelectedTime.informed" class="gradText">
              <span class="mediumText">Anspruch im Minderungszeitraum</span>
              {{ getSelectedRent }}€ / 31 Tage = {{ (getSelectedRent / 31).toFixed(0) }} € (Miete pro Tag)<br>
              x {{ days }} Tage (Minderungszeitraum)<br>
              x {{ min }} - {{ max }} % (Minderungsquote)<br>
              = {{ (getSelectedRent / 31 / 100 * min * days).toFixed(0) }} - {{ (getSelectedRent / 31 / 100 * max * days).toFixed(0) }} €
            </p>
            <p class="gradText">
              <span class="mediumText">Was muss ich beachten?</span><br>
              {{ getSelectedCase.important }}<br><br>
              Wird die Miete unberechtigt oder zu viel gemindert, kann eine Kündigung wegen Zahlungsverzugs drohen. Um dieses Risiko zu vermeiden, können künftige Mieten zunächst unter dem Vorbehalt der Rückforderung vollständig gezahlt und der Minderungsbetrag später zurückgefordert werden. Voraussetzung für eine Rückforderung ist, dass Sie sich bei der Zahlung die Rückforderung vorbehalten haben.
            </p>  
          </div>
        </div>
      </div>
    </div>
    <!-- Wichtig Modul -->
    <div id="wichtigModal" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Das ist wichtig</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <p>
              {{ getSelectedCase.important }}
            </p>
            <p>
              Wird die Miete unberechtigt oder zu viel gemindert, kann eine Kündigung wegen Zahlungsverzugs drohen. Um dieses Risiko zu vermeiden, können künftige Mieten zunächst unter dem Vorbehalt der Rückforderung vollständig gezahlt und der Minderungsbetrag später zurückgefordert werden. Voraussetzung für eine Rückforderung ist, dass Sie sich bei der Zahlung die Rückforderung vorbehalten haben.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataService from '@/services/DataService'
export default {
  name: 'RentCalculator',
  components: {
  },
  data() {
    return {
        result: '',
        days: '',
        daysTotal: 0,
        min: 0,
        max: 0
    }
  },
  computed: {
    ...mapGetters([
        'getSelectedCase', 'getSelectedGrade', 'getSelectedTime', 'getSelectedRent'
    ])
  },
  created () {
    this.$store.commit('setCurrentProgress', 5)
    DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 7, 'Ergebnis')
    this.calcResult()
  },
  methods: {
    calcResult() {
      console.log(this.getSelectedGrade)
      var shownResult = 0
      var rent = this.getSelectedRent
      this.min = this.getSelectedGrade.minReduce
      this.max = this.getSelectedGrade.maxReduce
      var Difference_In_Time = this.getRelevantDays()
      this.days = Difference_In_Time / (1000 * 3600 * 24)
      var minPerDay = rent / 31 * this.min / 100
      var maxPerDay = rent / 31 * this.max / 100
      shownResult = (minPerDay * this.days).toFixed(0) + ' - ' + (maxPerDay * this.days).toFixed(0) + '€'
      if( this.isInPast() === false || !this.getSelectedTime.informed) {
        shownResult = (rent / 100 * this.min).toFixed(0) + " - " + (rent / 100 * this.max).toFixed(0) + " €"
      }
      if (minPerDay == maxPerDay) {
        shownResult = shownResult.split('-')[1] //teile bei -, nimm 2. Element
      }
      this.result = shownResult
    },
    getRelevantDays() {
      var from = new Date(this.getSelectedTime.from)
      var to = new Date(this.getSelectedTime.to)
      var informed = new Date(this.getSelectedTime.informed)
      return to - informed < 0 ? 0 : to - informed
    },
    isInPast() {
      var now = new Date();
      var to = new Date(this.getSelectedTime.to)
      console.log("Hier jetzt schauen:")
      console.log(now)
      console.log(to)
      now.setHours(0,0,0,0)
      console.log(now)
      if (to < now) {
        return true
      } else {
        return false
      }
    },
    transformToFormat(dateString) {
      let dateObj = new Date(dateString);
      var dd = String(dateObj.getDate()).padStart(2, '0');
      var mm = String(dateObj.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = dateObj.getFullYear();
      var dateString = dd + '.' + mm + "." + yyyy
      return dateString
    }
  }
}
</script>