<template>
  <div>
    <div class="container viewContainer">
      <h1>Impressum</h1>
      <h2>Deutscher Mieterbund e.V.</h2>
      <p>
        Littenstraße 10<br />
        10179 Berlin<br />
        Telefon:  030 / 2 23 23 - 0<br />
        Telefax:  030 / 2 23 23 - 100<br />
        E-Mail:   info@mieterbund.de
      </p>
      <p>
        Vorstand gem. § 26 BGB<br />
        Lukas Siebenkotten<br />
        Beatrix Zurek<br />
        Randolph Fries<br />
        Hans-Jochem Witzke<br />
        Anke Matejka
      </p>
      <p>
        Vereinsregister Berlin 21456 Nz<br />
        Verantwortlich im Sinne des Telemediengesetzes: Lukas Siebenkotten, DMB-Präsident (Anschrift s.o.)<br />
        Der Verein hat eine Berufshaftpflichtversicherung bei der Allianz-Versicherung, Postfach, 10900 Berlin,<br /> 
        Geltungsbereich Deutschland.<br />
        Datenschutzbeauftragter: datenschutzbeauftragter@mieterbund.de
      </p>
    </div>
  </div>  
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Kontakt',
    components: {
    },
    data() {
      return { 
      }
    },
    computed: {
      ...mapGetters([
          'getSelectedTime'
      ])
    },
    methods: {
      getRelevantDays() {
        var from = new Date(this.getSelectedTime.from)
        var to = new Date(this.getSelectedTime.to)
        var informed = new Date(this.getSelectedTime.informed)
        return to - informed < 0 ? 0 : to - informed
      }
    }
  }
</script>